import { useCallback } from 'react';
import { request, RequestMethods } from '@haberkorn/react-requests';
import { ApiRefreshToken, ApiAuthorizationCode } from '../models';

export const useAuthApi = <UserMeModel,>(authApiRootUrl: string) => {

  return {

    //**************
    // Auth
    //**************
    registerAsync: useCallback((authorizationCode: string) => {
      return request<ApiRefreshToken, ApiAuthorizationCode>(RequestMethods.POST, authApiRootUrl, 'v1/register/viking', {authorizationCode});
    }, [request]),

    loginAsync: useCallback((authorizationCode: string) => {
      return request<ApiRefreshToken, ApiAuthorizationCode>(RequestMethods.POST, authApiRootUrl, 'v1/login/viking', { authorizationCode });
    }, [request]),

    logoutAsync: useCallback((refreshToken: string) => {
      return request<ApiRefreshToken, ApiRefreshToken>(RequestMethods.POST, authApiRootUrl, 'v1/logout', { refreshToken });
    }, [request]),
    
    refreshTokenAsync: useCallback((refreshToken: string) => {
      return request<ApiRefreshToken, ApiRefreshToken>(
        RequestMethods.POST,
        authApiRootUrl,
        'v1/refreshToken',
        {
          refreshToken
        }
      );
    }, [request]),


    //**************
    // User
    //**************
    getUserMeAsync: useCallback(() => {
      return request<UserMeModel, null>(RequestMethods.GET, authApiRootUrl, "v1/users/me");
    }, [request])
  }
};
