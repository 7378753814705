import axios from 'axios';
import { RequestMethods } from '../enumerations';

export const request = async <ResponseDataType, BodyType = any>(method: RequestMethods, rootUrl: string, path: string, body?: BodyType): Promise<ResponseDataType> => {
  const response = await axios({
    method,
    url: rootUrl + path,
    data: body,
    withCredentials: true
  });
  return response.data;
};
