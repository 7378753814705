import { useReducer, useCallback, Reducer } from "react";
import axios from 'axios';
import { RequestMethods } from "../enumerations";

export const enum RequestActions {
  SEND = "SEND",
  RESPONSE = "RESPONSE",
  ERROR = "ERROR",
  CLEAR = "CLEAR"
}

export interface IRequestState<ResponseDataType, CallbackActionType> {
  isLoading: boolean;
  error: IRequestError | null | undefined;
  data: ResponseDataType | null| undefined;
  callbackAction: CallbackActionType | null| undefined;
}

export interface IRequestHook<ResponseDataType, CallbackActionType>
  extends IRequestState<ResponseDataType, CallbackActionType> {
  sendRequest: (
    callbackAction: CallbackActionType,
    rootUrl: string,
    path: string,
    method: RequestMethods,
    body?: any
  ) => void;
  clear: () => void;
}

const initialState: IRequestState<any, any> = {
  isLoading: false,
  error: null,
  data: null,
  callbackAction: null
};

interface IRequestError {
  message: string;
}

interface IRequestAction<ResponseDataType, CallbackActionType> {
  type: RequestActions;
  callbackAction?: CallbackActionType;
  responseData?: ResponseDataType;
  error?: IRequestError;
}

const requestReducer = <ResponseDataType, CallbackActionType>(
  currentRequestState: IRequestState<ResponseDataType, CallbackActionType>,
  action: IRequestAction<ResponseDataType, CallbackActionType>
): IRequestState<ResponseDataType, CallbackActionType> => {
  switch (action.type) {
    case RequestActions.SEND:
      return {
        isLoading: true,
        error: null,
        data: null,
        callbackAction: action.callbackAction
      };
    case RequestActions.RESPONSE:
      return {
        ...currentRequestState,
        isLoading: false,
        data: action.responseData
      };
    case RequestActions.ERROR:
      return { ...currentRequestState, isLoading: false, error: action.error };
    case RequestActions.CLEAR:
      return initialState;
    default:
      throw new Error("Should not be reached!");
  }
};


export const useRequest = <ResponseDataType, CallbackActionType>(): IRequestHook<
  ResponseDataType,
  CallbackActionType
> => {
  const [requestState, dispatchRequest] = useReducer<
    Reducer<
      IRequestState<ResponseDataType, CallbackActionType>,
      IRequestAction<ResponseDataType, CallbackActionType>
    >
  >(requestReducer, initialState);

  const sendRequest = useCallback(
    <ResponseDataType>(
      callbackAction: CallbackActionType,
      rootUrl: string,
      path: string,
      method: RequestMethods,
      body?: ResponseDataType
    ) => {
      dispatchRequest({
        type: RequestActions.SEND,
        callbackAction: callbackAction
      });

      axios({
        method,
        url: rootUrl + path,
        data: body,
        withCredentials: true
      }).then((response) => {
        dispatchRequest({
          type: RequestActions.RESPONSE,
          responseData: response.data
        });
      }).catch((response) => {
        dispatchRequest({ type: RequestActions.ERROR, error: response });
      })
    },
    []
  );

  const clear = useCallback(
    () => dispatchRequest({ type: RequestActions.CLEAR }),
    []
  );

  return {
    isLoading: requestState.isLoading,
    data: requestState.data,
    error: requestState.error,
    callbackAction: requestState.callbackAction,
    sendRequest,
    clear: clear
  };
};
