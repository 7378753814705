import * as React from "react";
import { useCallback, useEffect, useReducer, useState, Props } from "react";
import { useHistory } from "react-router";
import { ApiRefreshToken } from '../models';
import { useAuthApi } from '../hooks';

const LOCAL_STORAGE_REFRESH_TOKEN_KEY = "AUTH_REFRESH_ROKEN";
const NUMBER_OF_TOKEN_REFRESH_RETRY = 3;

export interface IAuthState<UserMeModel> {
  isSilentlyLogin: boolean;
  isAuthenticated: boolean;
  currentUser: UserMeModel | undefined;
  refreshToken: ApiRefreshToken | null | undefined;
  isSessionReady: boolean;
  isWorking: boolean,
  error: any,
  onSessionReadyCallback?: ((urlParams: URLSearchParams) => void) | null,
  urlParams?: URLSearchParams | null
}

export interface IAuthContext<UserMeModel> extends IAuthState<UserMeModel> {
  register: (authorizationCode: string, urlParams: URLSearchParams, onSessionReadyCallback: (urlParams: URLSearchParams) => void) => void;
  login: (authorizationCode: string, urlParams: URLSearchParams, onSessionReadyCallback: (urlParams: URLSearchParams) => void) => void;
  logout: () => void;
  defaultOnSessionReadyCallback: (queryParameterName_RedirectPath: string) => (urlParams: URLSearchParams) => void;
}

const initialAuthState: IAuthState<any> = {
  isSilentlyLogin: true,
  isAuthenticated: false,
  refreshToken: null,
  currentUser: null,
  isSessionReady: false,
  onSessionReadyCallback: null,
  urlParams: null,
  isWorking: false,
  error: null
};

export const createAuthContext = <UserMeModel,>() => React.createContext<IAuthContext<UserMeModel>>({
  ...initialAuthState,
  register: () => {},
  login: () => {},
  logout: () => {},
  defaultOnSessionReadyCallback: () => () => {}
});

export const enum AuthActionTypes {
  START_WORKING = "START_WORKING",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  GOT_USER = "GOT_USER",
  SESSION_READY = "SESSION_READY",
  SESSION_SETUP_FAILED = "SESSION_SETUP_FAILED"
}

export interface IAuthAction<UserMeModel> {
  type: AuthActionTypes;
  refreshToken?: ApiRefreshToken;
  user?: UserMeModel;
  onSessionReadyCallback?: ((urlParams: URLSearchParams) => void) | null
  urlParams?: URLSearchParams | null
}





  // **********************************
  //         HANDLING TOKENS
  // **********************************


  const storeRefreshToken = (refreshToken: ApiRefreshToken): void => 
  {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken.refreshToken);
  }

  const getRefreshToken = (): string | null => 
  {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  }

  const clearRefreshToken = (): void => 
  {
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  }




const currentAuthStateReducer = <UserMeModel,>() => (
  currentAuthState: IAuthState<UserMeModel>,
  action: IAuthAction<UserMeModel>
): IAuthState<UserMeModel> => {
  switch (action.type) {
    case AuthActionTypes.START_WORKING:
      return {
        ...currentAuthState,
        isWorking: true
      }

    case AuthActionTypes.LOGGED_IN:
      if(action.refreshToken){
        storeRefreshToken(action.refreshToken);
      }
      return {
        ...currentAuthState,
        isAuthenticated: true,
        refreshToken: action.refreshToken || null,
        onSessionReadyCallback: action.onSessionReadyCallback,
        urlParams: action.urlParams
      };

    case AuthActionTypes.LOGGED_OUT:
      return {
        ...initialAuthState,
        isSilentlyLogin: false,
        isWorking: false
      };

    case AuthActionTypes.GOT_USER:
      return {
        ...currentAuthState,
        currentUser: action.user
      };

    case AuthActionTypes.SESSION_READY:
      return {
        ...currentAuthState,
        isSilentlyLogin: false,
        isSessionReady: true,
        isWorking: false
      };

    case AuthActionTypes.SESSION_SETUP_FAILED:
      return {
        ...currentAuthState,
        isSilentlyLogin: false,
        isSessionReady: false,
        isWorking: false
      };

    default:
      throw new Error("Should not get there!");
  }
};

export interface IAuthContextProvider<UserMeModel> extends Props<UserMeModel>
{
  authApiRootUrl: string;
  getAuthProvider: () => React.Provider<IAuthContext<UserMeModel>>
}

// TODO: useMemo or useCallback to avoid recreating all function at each page change
/**
 * Creation of the AuthContext provider
 * @param props
 */
export const AuthContextProvider: React.FC<IAuthContextProvider<any>> = <UserMeModel,>(props: IAuthContextProvider<UserMeModel>) => {

  // Reducers
  const [currentAuthState, dispatchCurrentAuthState] = useReducer(
    currentAuthStateReducer<UserMeModel>(),
    initialAuthState
  );

  // Hooks
  var api = useAuthApi<UserMeModel>(props.authApiRootUrl);
  var history = useHistory();

  // States
  const [refreshTokenIntervalState, setRefreshTokenIntervalState] = useState<boolean>(false);


  // **********************************
  //         GLOBAL EFFECTS
  // **********************************

  /**
   * Once logged in and session is ready, redirect user
   */
  useEffect(() => {
    if(currentAuthState.isAuthenticated && currentAuthState.isSessionReady && currentAuthState.urlParams){
      if(currentAuthState.onSessionReadyCallback)
        currentAuthState.onSessionReadyCallback(currentAuthState.urlParams);
    }
  }, [
    currentAuthState.isAuthenticated,
    currentAuthState.isSessionReady,
    currentAuthState.onSessionReadyCallback,
    currentAuthState.urlParams
  ])





  // **********************************
  //              LOGIN
  // **********************************

  // Functions definintion
  const loginHandler = useCallback(async (authorizationCode: string, urlParams: URLSearchParams, onSessionReadyCallback: (urlParams: URLSearchParams) => void): Promise<void> => {
    if(!currentAuthState.isAuthenticated || !currentAuthState.isSessionReady){
      dispatchCurrentAuthState({
        type: AuthActionTypes.START_WORKING
      });

      try{
        var response = await api.loginAsync(authorizationCode);

        // Starting token refresh interval
        setRefreshTokenIntervalState(true);
              
        dispatchCurrentAuthState({
          type: AuthActionTypes.LOGGED_IN,
          refreshToken: response,
          urlParams: urlParams,
          onSessionReadyCallback: onSessionReadyCallback
          });
      }
      catch(ex)
      {
        dispatchCurrentAuthState({
          type: AuthActionTypes.SESSION_SETUP_FAILED
        });
      }
    }
    else
    {
      onSessionReadyCallback(urlParams);
    }
  }, [api.loginAsync, setRefreshTokenIntervalState, dispatchCurrentAuthState, currentAuthState.isSessionReady, currentAuthState.isAuthenticated]);





  // **********************************
  //              REGISTER
  // **********************************

  // Functions definintion
  const registerHandler = useCallback(async (authorizationCode: string, urlParams: URLSearchParams, onSessionReadyCallback: (urlParams: URLSearchParams) => void): Promise<void> => {
    if(!currentAuthState.isAuthenticated || !currentAuthState.isSessionReady){
      dispatchCurrentAuthState({
        type: AuthActionTypes.START_WORKING
      });

      try{
        var response = await api.registerAsync(authorizationCode);
        
        // Starting token refresh interval
        setRefreshTokenIntervalState(true);
        
        dispatchCurrentAuthState({
          type: AuthActionTypes.LOGGED_IN,
          refreshToken: response,
          urlParams: urlParams,
          onSessionReadyCallback: onSessionReadyCallback
        });
      }
      catch(ex)
      {
        dispatchCurrentAuthState({
          type: AuthActionTypes.SESSION_SETUP_FAILED
        });
      }
    }
  }, [api.registerAsync, setRefreshTokenIntervalState, dispatchCurrentAuthState, currentAuthState.isAuthenticated, currentAuthState.isSessionReady ]);


  
  
  
  // **********************************
  //           SILENT LOGIN
  // **********************************

  const silentLogin = useCallback(() => {
    dispatchCurrentAuthState({
      type: AuthActionTypes.START_WORKING
    });

    refreshToken(0);
  }, []);

  useEffect(() => {
    silentLogin()
  }, []);









  // **********************************
  //              LOGOUT
  // **********************************

  const logoutHandler = useCallback(async (): Promise<void> => {
    dispatchCurrentAuthState({
      type: AuthActionTypes.START_WORKING
    });

    try{
      var refreshToken = getRefreshToken();
      if(refreshToken){
        await api.logoutAsync(refreshToken);
      }

      // Stopping token refresh interval
      setRefreshTokenIntervalState(false);
      clearRefreshToken();

      dispatchCurrentAuthState({
        type: AuthActionTypes.LOGGED_OUT
      });
    }
    catch(ex)
    {
      // TODO: warn user still logged in for 15 minutes

      // Stopping token refresh interval
      setRefreshTokenIntervalState(false);

      dispatchCurrentAuthState({
        type: AuthActionTypes.LOGGED_OUT
      });
    }
  }, [dispatchCurrentAuthState, setRefreshTokenIntervalState, clearRefreshToken, api.logoutAsync, getRefreshToken]);




  // **********************************
  //           REFRESH TOKEN
  // **********************************

  const [isLoading_TokenRefresh, setIsLoading_TokenRefresh] = useState<boolean>(false);

  const refreshToken =  useCallback(async (numberOfRetryBeforeLogout: number = NUMBER_OF_TOKEN_REFRESH_RETRY): Promise<void> => {
    if(!isLoading_TokenRefresh || numberOfRetryBeforeLogout != NUMBER_OF_TOKEN_REFRESH_RETRY){
      setIsLoading_TokenRefresh(true);
      try{
        var _refreshToken  = getRefreshToken();
        
        if(!_refreshToken){
          throw "Could not refresh token";
        }

        var response = await api.refreshTokenAsync(_refreshToken);

        if(!response || !response.refreshToken)
        {
          throw "Could not refresh token";
        }

        dispatchCurrentAuthState({
          type: AuthActionTypes.LOGGED_IN,
          refreshToken: response,
          onSessionReadyCallback: null,
          urlParams: null
        });
      }
      catch(ex)
      {
        if(numberOfRetryBeforeLogout)
        {
          setTimeout(() => {
            refreshToken(numberOfRetryBeforeLogout - 1);
          }, 3000);
        }
        else
        {
          logoutHandler();
        }
      }

    }
  }, [ api.refreshTokenAsync, dispatchCurrentAuthState, logoutHandler]);


  useEffect(() => {
    var interval: NodeJS.Timeout;
    if(refreshTokenIntervalState){
      interval = setInterval(() => {
        refreshToken();
      }, 60000); // 10 mins as the jwt token last for 15 mins
    }

    return () => {
      if(interval)
        clearInterval(interval);
    }
  }, [
    refreshTokenIntervalState,
    refreshToken
  ]);





  // **********************************
  //       SESSION PREPARATION
  // **********************************

  /**
   * Starts session preparation once user is logged_in
   */
  useEffect((): void => {
    if(currentAuthState.isAuthenticated && !currentAuthState.isSessionReady){
      api.getUserMeAsync()
      .then((user) => {
        dispatchCurrentAuthState({
          type: AuthActionTypes.GOT_USER,
          user
        });
      })
      .catch((ex) => {
        // TODO: warn user
        dispatchCurrentAuthState({
          type: AuthActionTypes.SESSION_SETUP_FAILED
        });
      });
    }
  }, [
    currentAuthState.isAuthenticated, 
    currentAuthState.isSessionReady, 
    api.getUserMeAsync,
    dispatchCurrentAuthState
  ]);

  /**
   * Updates the flag for session ready when auth state changes
   */
  useEffect(() => {
    if(currentAuthState.isAuthenticated && currentAuthState.currentUser){
      dispatchCurrentAuthState({type: AuthActionTypes.SESSION_READY});
    }
  }, [
    currentAuthState.isAuthenticated,
    currentAuthState.currentUser
  ]);






  //***********************************
  // DEFAULT ON SESSION READY CALLBACK
  //***********************************

  const defaultOnSessionReadyCallback = (queryParameterName_RedirectPath: string) => (urlParams: URLSearchParams): void => {
    if (urlParams) {
      var redirectPath = urlParams.get(queryParameterName_RedirectPath);
      if(redirectPath){
        history.push(redirectPath);
        return;
      }
    }
    history.push("/");
  }



  // **********************************
  //              RETURN
  // **********************************

  // Context value
  var contextValue: IAuthContext<any> = {
    isSilentlyLogin: currentAuthState.isSilentlyLogin,
    isAuthenticated: currentAuthState.isAuthenticated,
    currentUser: currentAuthState.currentUser,
    refreshToken: currentAuthState.refreshToken,
    isSessionReady: currentAuthState.isSessionReady,
    isWorking: currentAuthState.isWorking,
    error: currentAuthState.error,
    register: registerHandler,
    login: loginHandler,
    logout: logoutHandler,
    defaultOnSessionReadyCallback
  };

  
  const AuthContextProvider = props.getAuthProvider();

  // Rendering
  return (
    <AuthContextProvider value={contextValue}>
      {props.children}
    </AuthContextProvider>
  );
};
